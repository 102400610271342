import React, {useContext, useEffect, useState} from 'react';
import {InboxProps} from './Inbox.props';
import './Inbox.styles.css';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GridColDef, GridToolbarColumnsButton, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import {getStatusStatistic, getTriageInboxes, updateTriageInboxes, getLatestFilter, deleteEmail, archiveEmail, sendMessageEmail, purgeEmail, getSettings, getUsedEmail, authorizeToken
} from '../../api/emailTriage';
import {SearchMenuBar} from '../../components/organisms/SearchMenuBar';
import { Images } from '../../configs/images';
import {DataTable} from '../../components/molecules/DataTable';
import {noUserDataRedirect, toPages} from '../../utils/common';
import moment from 'moment';
import {Button} from '../../components/atoms/Button';
import {Modal} from '../../components/atoms/Modal';
import {PageHeader} from '../../components/organisms/PageHeader';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {GlobalContext} from '../../context/GlobalState';
import {ActionsColumn} from '../../components/molecules/StyledMenu';
import {TableDropdown} from "../../components/molecules/TableDropdown";
import {DateRangePicker} from '../../components/atoms/DateRangePicker';
import {InboxDetails} from '../InboxDetails';
import {TabGroup} from "../../components/molecules/TabGroup";
import {Toast} from "../../components/atoms/Toast";
import {DropdownContent} from "../../components/molecules/DropDownContent";
import {DropDownList} from "../../components/atoms/DropDownList";
import {Tooltip, TooltipProps, tooltipClasses, styled, Box} from "@mui/material";
import {ThreatScoreTooltip} from "../../components/atoms/ThreatScoreTooltip";
import {isMobileOnly} from "react-device-detect";
import ChangeWithZone from "../../hoc/ChangeWithZone";
import {SendMailModal} from "../../components/organisms/SendMailModal";
import {Pluralize} from '../../utils/common'
import {ARCHIVE, DELETE, PURGE_OPTION, SEND_EMAIL, MESSAGES_STATUS_CHANGED, LOGGED_AZURE_TITLE, SETTING_TYPE_REPORTED_EMAILS_ACTIONS} from "../../constants/contants";
import { defaultSettings, useRoleContext } from '../../context/RoleContext';
import jwt_decode from "jwt-decode";
import {RadialGraph} from '../../components/atoms/RadialGraph'
import {LoadingPage} from "../../components/organisms/LoadingPage";

const navigationLinks = [
  {
    name: 'Inbox'
  }
]

const inboxDetailLinks = [
  {
    name: "Inbox",
    href: "/inbox",
  },
  {
    name: "Message Details",
  },
];

const inboxDetailArchivedLinks = [
  {
    name: "Archived",
    href: "/archived",
  },
  {
    name: "Message Details",
  },
];

const actionRules:any = {
  "Action Rule 1": "Action Rule 1",
  "Action Rule 2": "Action Rule 2",
  "Action Rule 3": "Action Rule 3",
  "Action Rule 4": "Action Rule 4",
  "Action Rule 5": "Action Rule 5"

}

const actionCategory:any = {
  "Threat": "Threat",
  "Clean": "Clean",
  "Unknown": "Unknown"
}

const statusDropdown = {
  "Received": {bg: "#EDEDED", hover: "#EDEDED"},
  "In Review": {bg: "#E1EFEF", hover: "#E1EFEF"},
  "Resolved": {bg: "#f2fad9", hover: "#f2fad9"},
}

const priorityDropdown:any = {
  "High": {bg: "#ED1C24", hover: "#B20007"},
  "Medium": {bg: "#FCAB10", hover: "#D88E00"},
  "Low": {bg: "#03A9F5", hover: "#007AB2"},
  "Unknown": {bg: "#909090", hover: "#707070"}
}

const tabStatus: any = {
  '0': 'Received',
  '1': 'In Review',
  '2': 'Resolved',
  '3': 'Archived',
}

const tabTitles = ['Received', 'In Review', 'Resolved'];

const PERPAGES = 10;

const defaultTabFilterValues = {
  perPages: PERPAGES,
  page: 1,
  keyword: "",
  priority: ["High", "Medium", "Low", "Unknown"],
  category: ["Threat", "Clean", "Unknown"],
  threat_analysis: ["Analysing", "Threat detected", "Undetermined", "No threat"],
  sort: "desc" ,// ASC / DESC, default is DESC,
  sortType: "priority" ,// default is date time,
};

const assignFilterValues = (prev: any, filterData: any) => ({
  ...prev,
  'priority': filterData.priority.length === defaultTabFilterValues.priority.length ? [] : filterData.priority,
  'category': filterData.category.length === defaultTabFilterValues.category.length ? [] : filterData.category,
  'threat_analysis': filterData.threat_analysis.length === defaultTabFilterValues.threat_analysis.length ? [] : filterData.threat_analysis
});

const InboxFull: React.FC<InboxProps> = ({isOpen, refKey, isArchived, setIsArchivedEmail, ...props}) => {
  const { id } = useParams();
  const [filterParams, setFilterParams] = useState({});
  const [isShowLoading, setShowLoading] = useState(false);
  const [inboxData, setInboxData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState<any>("desc");
  const [sortType, setSortType] = useState<any>("priority")
  const [perPages, setPerPages] = useState(PERPAGES);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowSelected, setRowSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [contentModal, setContentModal] = useState({title: '', content: '', action: '', textAgree: '', children: '', variant: ''});
  const [startPoint, setStartPoint] = useState(0);
  const [endPoint, setEndPoint] = useState(0);
  const [toastValue, setToastValue] = useState({type: '', message: '', isOpen: false, toastId: 0})
  const [statusTab, setStatusTab] = useState({'Received': 0, 'In Review': 0, 'Resolved': 0, 'Archived': 0})
  const [modalID, setModalID] = useState(1)
  const [tabValue, setValue] = useState(0);
  const [inboxDetail, setInboxDetail] = useState<any>({})
  // @ts-ignore
  const userData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : {};
  const zoneID:any = sessionStorage.getItem('currentZone') || localStorage.getItem('currentZone');
  const [timer, setTimer] = useState(null);
  const [timerExpire, setTimerExpire] = useState(0);
  const [triggerLoadLog, setTriggerLoadLog] = useState(1)
  // validate from name on send email modal
  const [validateFromName, setValidateFromName] = useState(false);
  const [validateToField, setValidateToField] = useState(false);
  const [validateEmailContent, setValidateEmailContent] = useState(false);

  // validate from name on send email modal
  const [validateUserName, setValidateUserName] = useState(false);
  // validate from name on send email modal
  const [validateBaseSender, setValidateBaseSender] = useState(false);
  const [filterSearchKeyword, setFilterSearchKeyword] = useState('');
  const { setting, setSetting } = useRoleContext()
  const [loadingPage, setLoadingPage] = useState(false)

  let tz_name = 'Etc/UTC';
  let user_id = ""
  let user_role = ""
  const localStorageUserData = localStorage.getItem('userData');
  if(localStorageUserData) {
    const parseUserData = JSON.parse(localStorageUserData)
    tz_name = parseUserData["tz_name"]
    user_id = parseUserData["userId"]
    user_role = parseUserData["role"]
  }

  const handleChangeTitle = (e:any, type:any) => {
    const typeValue = e.target.getAttribute("value")
    const newRule = {[type]: typeValue}
    updateInbox(newRule)
  }

  const ThreatAnalysisTooltipStyle = styled(({ className, ...props }: TooltipProps) => (
      <Tooltip className={'custom-tooltip'} {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#ffffff',
      color: '#212121',
      alignItems: "center",
      maxWidth: "480px",
      borderRadius: "4px",
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.12)',
      fontSize: '14px',
      padding: '16px',
    },
  }));

  const columns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      cellClassName: 'checkbox-col'
    },
    {
      field: 'priority', headerName: 'Priority', width: 120, sortable: true,
      /* istanbul ignore next */
      renderCell: (row) => {
        return isArchived ? <TableDropdown emailId={row.id} title={row.formattedValue} variant='priority' background={priorityDropdown[row.formattedValue]['bg']} backgroundHover={priorityDropdown[row.formattedValue]['hover']} borderRadius={100}/> : <TableDropdown onClick={(e:any) => handleChangeTitle(e, row.field)} emailId={row.id} dropdownValue={setDropdownValue} title={row.formattedValue} variant='priority' background={priorityDropdown[row.formattedValue]['bg']} backgroundHover={priorityDropdown[row.formattedValue]['hover']} borderRadius={100} data={priorityDropdown}/>
      }
    },
    {
      field: 'threat_score',
      headerName: 'Threat Analysis',
      width: 160, sortable: false,
      headerClassName: 'MuiDataGrid-columnHeaderTitle',
      /* istanbul ignore next */
      renderHeader: () => (
          <Box>
            Threat Analysis
            <ThreatAnalysisTooltipStyle placement={"top"} className={"custom-tooltip"} title={<ThreatScoreTooltip />} >
              <InfoOutlinedIcon fontSize={"small"} sx={{marginLeft: '8px', position: "relative", top: "5px", cursor: "pointer"}} htmlColor={"#193051"}/>
            </ThreatAnalysisTooltipStyle>
          </Box>
      ),
      renderCell: (row) => {
        return row.formattedValue === -1 ? <Tooltip placement={"top"} title={'Scanning in progress. No threat score yet.'}>
              <span>ANALYSING...</span>
            </Tooltip> : <RadialGraph value={row.formattedValue} isToolTipHover={true} chartStyle={{stroke: 'unset'}}/>
      }
    },
    {
      field: 'status',
      headerName: 'Process Status',
      sortable: true,
      width: 120,
      /* istanbul ignore next */
      renderCell: (row) => {
        return isArchived ? <TableDropdown emailId={row.id} title={row.formattedValue} variant='status' color='#212121' background={statusDropdown[row.formattedValue]['bg'] } backgroundHover={statusDropdown[row.formattedValue]['hover']} borderRadius={2} padding={'4px 4px 4px 8px'} /> : <TableDropdown onClick={(e:any) => handleChangeTitle(e, row.field)} emailId={row.id} dropdownValue={setDropdownValue} title={row.formattedValue} variant='status' color='#212121' background={statusDropdown[row.formattedValue]['bg'] } backgroundHover={statusDropdown[row.formattedValue]['hover']} borderRadius={2} padding={'4px 4px 4px 8px'} data={statusDropdown}/>
      }
    },

    { field: 'category',
      headerName: 'Category',
      width: 120,
      sortable: true,
      /* istanbul ignore next */
      renderCell: (row) => {
        return isArchived ? <TableDropdown emailId={row.id} title={row.formattedValue} variant='category' color='#212121' background='transparent' backgroundHover='transparent' borderRadius={100}/> : <TableDropdown onClick={(e:any) => handleChangeTitle(e, row.field)} emailId={row.id} dropdownValue={setDropdownValue} title={row.formattedValue} variant='category' color='#212121' background='transparent' backgroundHover='transparent' borderRadius={100} data={actionCategory}/>
      }
    },
    { field: 'email_from',
      headerName: 'From (Email)',
      width: 260,
      sortable: false,
      renderCell: (row) => {
        return <Tooltip placement={"top"} title={row.formattedValue}>
          <span className={'MuiDataGrid-cellContent'}>{row.formattedValue}</span>
        </Tooltip>
      }
    },
    {
      field: 'subject',
      headerName: 'Subject',
      width: isOpen ? 450 : 300,
      sortable: true,
      renderCell: (row) => {
        return <Tooltip placement={"top"} title={row.formattedValue}>
          <span className={'MuiDataGrid-cellContent'}>{row.formattedValue}</span>
        </Tooltip>
      }
    },
    {
      field: 'reported_at',
      headerName: 'Reported at',
      width: 120,
      renderCell: (row) => {
        return (<div>
          <span>{moment.utc(row.formattedValue, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('DD/MM/YYYY')}</span>
          <br/>
          <span>{moment.utc(row.formattedValue, 'DD/MM/YYYY hh:mm:ss').tz(tz_name).format('hh:mm A')}</span>
        </div>)
      }
    },
    {
      field: 'reported_by',
      headerName: 'Reported by (Email)',
      width: 220,
      sortable: false,
      renderCell: (row) => {
        return <Tooltip placement={"top"} title={row.formattedValue}>
          <span className={`MuiDataGrid-cellContent ${isArchived ? '' : 'last-cell'}`}>{row.formattedValue}</span>
        </Tooltip>
      }
    },

  ];

  if (isArchived) {
    columns.push({
      field: 'archived_at',
      headerName: 'Archived at',
      width: 180,
      renderCell: (row) => {
        return (<div className={`${isArchived ? 'last-cell' : ''}`}>
          <span>{moment.utc(row.formattedValue, 'YYYY-MM-DD hh:mm:ss').tz(tz_name).format('DD/MM/YYYY')}</span>
          <br/>
          <span>{moment.utc(row.formattedValue, 'YYYY-MM-DD hh:mm:ss').tz(tz_name).format('hh:mm A')}</span>
        </div>)
      }
    })
  }

  const formatAMPM = (date:any) => {
    var hours = date.hours();
    var minutes = date.minute();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? '0' + hours : hours
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  const mobileColumns: GridColDef[] = [
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      cellClassName: 'checkbox-col',
    },
    {
      field: 'email_from',
      headerName: 'From (Email)',
      sortable: false,
      cellClassName: 'email-content',
      renderCell: (rowCell) => {
        return <div className='info-mobile-row' style={{padding: '10px 0', fontSize: '14px'}}>
          <div className='row first-row'>
            <b>{rowCell.row.email_from}</b>
            <span className={`priority ${rowCell.row.priority}`} style={{borderRadius: '100px', color: '#fff', padding: '4px 8px'}}>{rowCell.row.priority}</span>
          </div>
          <div className='row second-row'>
            <div className='subject'>{rowCell.row.subject}</div>
            <div className='datetime'>
              <span className='date'>{moment.utc(rowCell.row.date_scanned).tz(tz_name).format('DD/MM/YYYY')}</span>
              <span className='time'>{formatAMPM(moment(rowCell.row.date_scanned))}</span>
            </div>
          </div>
          <div className='row third-row'>
            <label>Status: <span style={{background: '#EDEDED', borderRadius: '2px', color: '#212121', padding:'2px 8px', fontWeight:'600'}}>{rowCell.row.status}</span>&nbsp; | &nbsp;</label>
            <label>Category: <b>{rowCell.row.category}</b></label>
          </div>
        </div>
      }
    }
  ];

  const defaultFilter: any = {
    tabs: [
      ...tabTitles.map(_ => defaultTabFilterValues),
    ],
    archived: defaultTabFilterValues,
    startDate: moment().utc().subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss'),
    endDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
  }

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [dropdownValue, setDropdownValue] = useState<any>('');
  const open = Boolean(anchorEl);
  const [filterStatus, setFilterStatus] = useState<any>({status: isArchived ? ['Archived'] : ['Received']});
  const [inboxStatistic, setInboxStatistic] = useState<any>({});

  const [dateRange, setDateRange] = useState({
    startDate: moment.utc(defaultFilter.startDate).tz(tz_name).format('MM/DD/YYYY'),
    endDate: moment.utc(defaultFilter.endDate).tz(tz_name).format('MM/DD/YYYY'),
  })
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [inboxEvent, setInboxEvent] = useState<any>({id: id || '', selectedEmail: ''});

  const isAuthenticated = sessionStorage.getItem("isAuthenticated")
  const accessToken:any = sessionStorage.getItem("accessToken")

  const [usedEmail, setUsedEmail] = useState<any>(null)

  const assignSelectedItems = (values: any) => setSelectedItems((prev: any) => assignFilterValues(prev, values));

  const getFilterData = () => JSON.parse(sessionStorage.getItem('filter') || '{}');
  const getCurrentFilter = (filterData: any) => {
    if(!filterData['tabs']) {
      filterData['tabs'] = {
        [tabValue]: {}
      }
    }
    const tab = isArchived ? filterData.archived : filterData['tabs'][tabValue];
    const { startDate, endDate } = filterData;
    return Object.assign(tab || {}, { startDate, endDate });
  };
  const saveFilter = (filter: any) => sessionStorage.setItem('filter', JSON.stringify(filter));

  const resetFilters = (resetAll=false) => {
    const filterData = getFilterData();
    const dates = {
      startDate: filterData.startDate,
      endDate: filterData.endDate,
    };
    if (resetAll) {
      Object.assign(filterData, defaultFilter);
    } else {
      const currentTabFilter = getCurrentFilter(filterData);
      Object.assign(currentTabFilter, defaultTabFilterValues);
    }
    saveFilter(filterData);

    setFilterSearchKeyword(defaultTabFilterValues.keyword);
    setPerPages(defaultTabFilterValues.perPages);
    setDateRange({
      startDate: moment.utc(filterData.startDate).tz(tz_name).format('MM/DD/YYYY'),
      endDate: moment.utc(filterData.endDate).tz(tz_name).format('MM/DD/YYYY'),
    });
    assignSelectedItems(defaultTabFilterValues);

    const params = {
      ...defaultTabFilterValues,
      ...filterStatus,
      ...dates,
    }
    setCurrentPage(1);
    setInboxData([]);
    getInboxes(1, params);
    getStatusTab(params);
  }

  const showActionsDropdown = (event: React.MouseEvent<HTMLElement>) => {
    const emailId:any = event.currentTarget.getAttribute('data-emailid');
    const rowId = document.querySelector('[data-id="'+emailId+'"]');
    if(rowId) {
      rowId.classList.add('focusing')
    }
    handleGetUsedEmail({settingType: "default_email"})
    setAnchorEl(event.currentTarget);
    getInboxDetail(emailId)
  };
  const hideActionsDropdown = () => {
    const gridRows = document.querySelectorAll('.MuiDataGrid-row');
    if(gridRows) {
      for(let i = 0; i < gridRows.length; i++) {
        gridRows[i].classList.remove('focusing');
      }
    }
    setAnchorEl(null);
  };

  const [columnData, setColumnData] = useState<any>(null)

  const getFilterColumn = (data:object) => {
    return getLatestFilter(data).then((result) => {
      const a = result.data;
      if (a === "nodata") {
        sessionStorage.setItem("dynamicColumn", JSON.stringify({}));
        setColumnData({})
      } else {
        sessionStorage.setItem("dynamicColumn", a.dynamic_column);
        setColumnData(JSON.parse(a.dynamic_column))
      }
      return a;
    });
  }

  const loadDataTable = () => {
    const userData = localStorage.getItem('userData');
    if (userData === null) {
      noUserDataRedirect()
    } else {
      const userId = JSON.stringify(JSON.parse(userData)["userId"]);

      const filter = sessionStorage.getItem("filter");

      if (filter === null) {
        const filterParams = { ...defaultFilter, ...filterStatus };
        getInboxes(1, filterParams);
        getStatusTab(filterParams);
        getFilterColumn({"userId": userId});
        sessionStorage.setItem("filter", JSON.stringify(filterParams));
      } else {
        const filterData = JSON.parse(filter);

        const currentTabFilter = getCurrentFilter(filterData);
        assignSelectedItems(currentTabFilter);
        getFilterColumn({"userId": userId});
        getStatusTab(currentTabFilter);
        getInboxes(1, { ...currentTabFilter, ...filterStatus });
      }
      getSettings().then((response) => {
        const newSettings = { ...defaultSettings };
        response.data.forEach((item: any) =>
          Object.assign(newSettings, { [item.setting_type]: item.setting_value })
        );
        setSetting(response.data.length > 0 ? newSettings : defaultSettings);
      }).catch((e) => {
        console.error(e)
      });
    }
  }

  useEffect(() => {
    loadDataTable()
    const dataParams:any = {settingType: "default_email"}
    handleGetUsedEmail(dataParams)
  },[])
  const navigate = useNavigate();
  const location = useLocation();

  const { globalDispatch, globalState } = useContext(GlobalContext);
  const isMS365 = globalState.ms365

  useEffect(() => {
    resetFilters(true);
    if(isArchived){
      setFilterStatus({status: ['Archived']})
    }
  }, [globalState.currentZone]);

  const rowParams = (rowUpdated:any, newRule:any) => {
    if(rowUpdated) {
      return {
        "emailId": rowUpdated.email_id,
        "category": newRule?.category || rowUpdated.category,
        "priority": newRule?.priority || rowUpdated.priority,
        "status": newRule?.status || rowUpdated.status
      }
    }
  }

  const updateInbox = (newRule:any) => {
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;
    let rowUpdated:any;
    if(rowEmailId) {
      rowUpdated = inboxData.find((row:any) => row.email_id === rowEmailId.toString());
    }
    if(rowUpdated) {
      const params = rowParams(rowUpdated, newRule)
      const inboxUpdated:any = inboxData.map((row:any) => {
        return row.email_id === rowEmailId ? { ...row, priority: params?.priority, status: params?.status, category: params?.category }: row;
      });
      setInboxData(inboxUpdated);

      return updateTriageInboxes(params).then((result) => {
        if(result.status === 200) {
          setRowSelected([])
        }
      })
    } else {
      const selectedRowsData = rowSelected.map((id:any) => inboxData.find((row:any) => row.email_id === id));
      let inboxesUpdated:any;

      inboxesUpdated = inboxData.map((rowData:any) => {
        const found = selectedRowsData.find((rowS: any) => rowS.email_id === rowData.email_id);
        if (found) {
          const rowParam = rowParams(found, newRule)
          rowData = Object.assign(rowData, {priority: rowParam?.priority, status: rowParam?.status, category: rowParam?.category});
          updateTriageInboxes(rowParam).then((result) => {
            if(result.status === 200) {
              setRowSelected([])
            }
          })
        }
        return rowData;
      });
      setInboxData(inboxesUpdated)
    }
  }

  const getInboxes = (page: number, params: any, perPageNav?: number, onSort?: string, sortTypeTable?: string) => {
    let data = null;
    setShowLoading(true);
    params["page"] = page;
    params["sort"] = onSort || sort;
    params["perPages"] = perPageNav || perPages;
    params["sortType"] = sortTypeTable || sortType;

    if (timer) {
      clearTimeout(timer);
      setTimer(null);
    }
    const timers: any = setTimeout( async () => {
       data = await getTriageInboxes(params);
        if(data && data.status === 200) {
        const a = data.data;
        setShowLoading(false);
        setInboxData(a.list);
        setTotalRows(a.totalItems);
        setStartPoint(a.startItemsPerPage);
        setEndPoint(a.endItemsPerPage)
        setTotalPages(a.maxPage);
        setInboxStatistic(a.statistic)
        hideActionsDropdown()
      }
    }, 800);
    setTimer(timers);
  };

  const getStatusTab = (params: any) => {
    getStatusStatistic(params).then((result) => {
      const data = result.data;
      setStatusTab({'Received': data.totalStaReceived, 'In Review': data.totalStaReview, 'Resolved': data.totalStaResolved, 'Archived': data.totalArchive})
    })
  }

  const setActiveFilter = (item:any) => {
    const status = { status: [item] };
    setFilterStatus(status);

    const filterData = getFilterData();
    Object.assign(filterData, status);
    saveFilter(filterData);
    const currentTabFilter = getCurrentFilter(filterData);

    assignSelectedItems(currentTabFilter);
    setFilterSearchKeyword(currentTabFilter.keyword);
    setPerPages(currentTabFilter.perPages)

    getInboxes(1, {...currentTabFilter, ...status}, currentTabFilter.perPages);
    setCurrentPage(1);
    getStatusTab(currentTabFilter);
  }

  const handleFilter = (item: any, type: any) => {
    setSelectedItems((prev: any) => {
      return ({
        ...prev,
        [type]: item,
      });
    });
    const filterData = getFilterData();
    const currentTabFilter = getCurrentFilter(filterData);

    if (typeof item === 'object') {
      if (item.length > 0) {
        Object.assign(currentTabFilter, { [type]: item });
      } else {
        Object.assign(currentTabFilter, {
          [type]: isArchived ? defaultFilter.archived[type] : defaultFilter['tabs'][tabValue][type]
        });
      }
    }
    saveFilter(filterData);

    setCurrentPage(1);
    setInboxData([]);
    getInboxes(1, { ...currentTabFilter, ...filterStatus });
    getStatusTab(currentTabFilter);
  };

  const handleClearFilter = (type: any) => {
    setSelectedItems((prev:any) => {
      return ({
        ...prev,
        [type]: [],
      })
    });
  };

  const handlePageChange = (page: number) => {
    const currentFilter = getCurrentFilter(getFilterData());
    setCurrentPage(page);
    getInboxes(page, {...currentFilter, ...filterStatus});
    getStatusTab(currentFilter);
  }

  const handlePerRowsChange = (e: any) => {
    const value = parseInt(e.target.value);
    setPerPages(value);
    const filterData = getFilterData();
    const currentFilter = getCurrentFilter(filterData);
    Object.assign(currentFilter, { perPages: value });
    saveFilter(filterData);

    setCurrentPage(1);
    getInboxes(1, {...currentFilter, ...filterStatus}, value);
    getStatusTab(currentFilter);
  };

  const actionOtherData:any = isArchived ? {
    "Delete": DELETE,
    } : setting[SETTING_TYPE_REPORTED_EMAILS_ACTIONS] && isMS365 ? {
      "SendMail": SEND_EMAIL,
      "Purge": PURGE_OPTION,
      "Archive": ARCHIVE,
      "Delete": DELETE,
    } : {
      "SendMail": SEND_EMAIL,
      "Archive": ARCHIVE,
      "Delete": DELETE,
    }

  const customToolbar = () => {
    return (
      <div className='toolbar'>
        <div className={"left-toolbar " + (disabledActions ? 'hide-left-toolbar': 'show-left-toolbar')}>
          {disabledActions ? '' :
            <div className={"rows-selected"}>
              <Button
                linkIcon={Images.CloseIcon}
                margin={"0 13px 0  0"}
                backgroundColor={"transparent"}
                variant={'only-icon'}
                fontSize={14}
                width={24}
                iconWidth={14}
                iconHeigth={14}
                color={'#011111'}
                onClick={() => setRowSelected([])}
              />
              <span>{totalRowsSelected} Selected</span>
            </div>
          }
          <DropDownList
            className={`${isArchived ? 'is_archived' : ''}`}
            width={124}
            height={36}
            border={"none"}
            variantDropdown={'actions-dropdown-inbox'}
            selectedTitle={'Actions'}
            disabled={(disabledActions)}
            children={<DropdownContent open={true} menuData={rowSelected} actionOtherData={actionOtherData} handleOpenSendMail={handleSendMail} handleDeleteMail={handleActionDelete} handleArchiveMail={handleArchiveMail} handlePurgeMail={handlePurgeMail} isAuthenticatedAzure={isAuthenticated} loginAzure={LoginAzure} hasAccessToken={accessToken}/>}
          />
        </div>
        {!isMobileOnly ? <div className={"right-toolbar"}>
          <GridToolbarColumnsButton startIcon={null} onResize={undefined} onResizeCapture={undefined}/>
        </div> : null}
      </div>
    )
  }

  const paginationComponent = () => {

    const perRowsPages = [5, 10, 20];
    const pageItems = toPages(totalPages);
    const nextDisabled = currentPage === pageItems.length ? 'disabled': '';
    const previousDisabled = currentPage === 1 ? 'disabled': '';

    return <nav className='pagination'>
      <div className='pagination-flex'>

        <span className='rowPerPages' style={{marginRight: 8}}>Rows per page</span>
        <select value={perPages} onChange={handlePerRowsChange} style={{width: 59}}>
          {
            perRowsPages.map(page => <option key={page} value={page}>{page}</option>)
          }
        </select>

        <span className='totalRows' style={{marginRight: 32, marginLeft: 32}}> {startPoint}-{endPoint} of {totalRows}  </span>

        <ul className="pagination-numbers">
          <li data-testid="first-page" onClick={() => handlePageChange(1)} className={'first-page ' + previousDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M18.41 16.59L13.82 12l4.59-4.59L17 6l-6 6 6 6zM6 6h2v12H6z"></path><path fill="none" d="M24 24H0V0h24v24z"></path></svg></li>
          <li data-testid="previous-page" onClick={() => handlePageChange(currentPage - 1)} className={'previous-page ' + previousDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></li>
          <li data-testid="next-page" onClick={() => handlePageChange(currentPage + 1)} className={'next-page ' + nextDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg></li>
          <li data-testid="last-page" onClick={() => handlePageChange(pageItems.length)}  className={'last-page ' + nextDisabled}><svg xmlns="http://www.w3.org/2000/svg" width="18" height="24" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><path d="M5.59 7.41L10.18 12l-4.59 4.59L7 18l6-6-6-6zM16 6h2v12h-2z"></path><path fill="none" d="M0 0h24v24H0V0z"></path></svg></li>
        </ul>
      </div>
    </nav>
  }

  const onSelectedRowsChange = (rows:any) => {
    const selectedRowsData = rows.map((id:any) => inboxData.find((row:any) => row.email_id === id));
    setRowSelected(selectedRowsData);
  }

  const onSort = (model:any , _: any) => {
    const currentTabFilter = getCurrentFilter(getFilterData());
    setSort(model?.length !== 0 ? model[0].sort : 'desc');
    setSortType(model?.length !== 0 ? model[0].field : 'date');
    getInboxes(
      1,
      {...currentTabFilter, ...filterStatus},
      perPages,
      model?.length !== 0 ? model[0].sort : 'desc',
      model?.length !== 0 ? model[0].field : 'date'
    );
    getStatusTab(currentTabFilter);
  };

  const onSearchInboxByKeywords = (event:any) => {
    if(event.keyCode === 13) {
      const filterData = getFilterData();
      const currentTabFilter = getCurrentFilter(filterData);
      Object.assign(currentTabFilter, { keyword: event.target.value });
      saveFilter(filterData);
      getInboxes(1, { ...currentTabFilter, ...filterStatus });
    }
  }

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleDoubleClickItem = (event:any) =>{
    const fields = ['category', '__check__', 'status', 'priority']
    if(!fields.includes(event.field)) {
      globalDispatch({
        type: 'UPDATE_SELECTED_EMAIL',
        payload: event.row
      })
      setInboxEvent({id: event.id, selectedEmail: event.row})
      setIsArchivedEmail(event.row.is_archive)
      if(event.row.is_archive){
        navigate('/archived/' + event.id, {
          state: {
            selectedEmail: event.row
          }
        })
      } else {
        navigate('/inbox/' + event.id, {
          state: {
            selectedEmail: event.row
          }
        })
      }

    }
  }

  const handleApplyDateRange = (picker:any) => {
    const { startDate, endDate } = picker;
    setDateRange({
      startDate: moment.utc(startDate).tz(tz_name).format('MM/DD/YYYY'),
      endDate: moment.utc(endDate).tz(tz_name).format('MM/DD/YYYY'),
    });

    const filterData = getFilterData();
    Object.assign(filterData, { startDate, endDate });
    saveFilter(filterData);

    const currentTabFilter = getCurrentFilter(filterData);
    setCurrentPage(1);
    setInboxData([]);
    getInboxes(1, { ...currentTabFilter, ...filterStatus });
    getStatusTab(currentTabFilter);
  };

  const handleActionDelete = (params?:any, page?: string) => {
    const emailItems:any = []
    let deleteParams:any = {
      emailItems: []
    }
    let deleteMessage:any = ''
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;
    if(rowSelected.length !== 0 && ((typeof params) === 'object' && params.length === 0 && rowEmailId === '')){
      // multi checkbox
      rowSelected.map((id:any) => emailItems.push({
        "email_id": id.toString(),
        "zone_id": zoneID.toString()
      }));
      deleteParams['emailItems'] = emailItems
      deleteMessage = emailItems.length > 1 ? `${emailItems.length.toString()} messages` : `${emailItems.length.toString()} message`
    } else if(rowEmailId.length !== 0 && (typeof params) === 'object'){
      // action column
      deleteParams['emailItems'].push({
        "email_id": rowEmailId,
        "zone_id": zoneID.toString()
      })
      deleteMessage = `${deleteParams['emailItems'].length.toString()} message`
    } else {
      // action in message details
      deleteParams['emailItems'] = params
      deleteMessage = 'message'
    }
    setAnchorEl(null)
    return deleteEmail(deleteParams).then((result:any) => {
      setModalID(modalID + 1)
      setToastValue({type: 'success', message: `Deleted ${deleteMessage} successfully!`, isOpen: true, toastId: modalID})
      if (params && isArchived){
        setInboxEvent({id: '', selectedEmail: ''})
        navigate('/archived')
      } else {
        navigate('/inbox')
      }
      loadDataTable()
    });
  }

  const handleEmailChange = (params?:any) => {
    loadDataTable()
  }

  const handleArchiveMail = (params?:any) => {
    const emailItems:any = []
    let archiveParams:any = {
      archived_at: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      itemsArchive: []
    }
    let archiveMessage:any = ''
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;
    if(rowSelected.length !== 0 && ((typeof params) === 'object' && params.length === 0 && rowEmailId === '')){
      // multi checkbox
      rowSelected.map((id:any) => emailItems.push({
        "email_id": id.toString(),
      }));
      archiveParams['itemsArchive'] = emailItems
      archiveMessage = emailItems.length > 1 ? `${emailItems.length.toString()} messages` : `${emailItems.length.toString()} message`
    } else if(rowEmailId.length !== 0 && (typeof params) === 'object'){
      // action column
      archiveParams['itemsArchive'].push({
        "email_id": rowEmailId,
      })
      archiveMessage = `${archiveParams['itemsArchive'].length.toString()} message`
    } else {
      // action in message details
      archiveParams['itemsArchive'] = params
      archiveMessage = 'message'
    }
    setAnchorEl(null)
    return archiveEmail(archiveParams).then((result:any) => {
      setModalID(modalID + 1)
      setToastValue({type: 'success', message: `Archived ${archiveMessage} successfully!`, isOpen: true, toastId: modalID})
      if (params){
        navigate('/inbox')
      }
      loadDataTable()
    });
  }

  const getInboxDetail = (params:any) => {
    inboxData.map((row:any) => {
      return row.email_id === params ? setInboxDetail({
        emailId: params,
        subject: row?.subject,
        reportedBy: row?.reported_by,
        priority: row?.priority,
        threatAnalysis: row?.threat_score,
        status: row?.status,
        category: row?.category,
        scanResult: row?.scan_result,
        sender: row?.email_from,
        recipient: row?.to,
      }) : row;
    });
  }

  const handleGetUsedEmail = (dataParams:object) => {
    getUsedEmail(dataParams).then((res) => {
      setUsedEmail(res.data)
    }).catch((res) => {
      console.log(res)
    })
  }

  // effect when clicking checkbox
  useEffect(() => {
    const params = rowSelected.join('')
    if (rowSelected.length === 1){
      getInboxDetail(params)
    } else {
      setInboxDetail({})
    }
  }, [rowSelected])

  // effect when clicking archived page
  useEffect(() => {
    if(isArchived){
      setActiveFilter('Archived')
      if(!(location.pathname.split('/archived/').length === 2)){
        setInboxEvent({id: '', selectedEmail: ''})
      }
    } else {
      setActiveFilter(tabStatus[tabValue]);
    }
  }, [isArchived, tabValue])

  const defaultParams: any = {
    subject: inboxDetail?.subject || '',
    from: userData.email,
    fromName: 'No-Reply',
    to: [],
    cc: [],
    originalReporter: true,
    statusToResolved: true,
    content: '',
    base64Data: '',
    originalEmail: '',
    recipients: [],
    reportedBy: inboxDetail?.reportedBy || '',
    userName: 'phriendlyphishing',
    baseSender: null,
  }

  const [paramSendMail, setParamSendMail] = useState(defaultParams)
  const [openSendMail, setOpenSendMail] = useState(false)
  const closeSendMail = () => {
    setOpenSendMail(!openSendMail);
    setValidateToField(false);
    setValidateFromName(false);
    setValidateEmailContent(false);
    setValidateUserName(false);
    setValidateBaseSender(false);
  }

  const handleSendMail = () => {
    setOpenSendMail(!openSendMail)
    setAnchorEl(null)
    hideActionsDropdown()
    setParamSendMail(() => {
      return ({
        ...defaultParams,
        baseSender: usedEmail && usedEmail.length !== 0 ? usedEmail : "",
        fromName: usedEmail && usedEmail.length !== 0 ? usedEmail?.from_name : "No-Reply",
      })
    });
  }

  const validatePopupSendMail = () => {
    const editorValue = document.getElementsByClassName("jodit-wysiwyg");
    if(editorValue){
      paramSendMail['content'] = editorValue[0].innerHTML;
    }
    if(paramSendMail[ 'to' ].length === 0||
        !paramSendMail[ 'content' ] ||
        !paramSendMail[ 'content' ]?.replace(/<\/?[^>]+(>|$)/g, "")||
        !paramSendMail['baseSender']?.mail_sender_id) {

      if(!paramSendMail['baseSender']?.mail_sender_id) {
        setValidateBaseSender(true);
        setModalID(modalID + 1);
        setToastValue({type: 'error', message: 'Please complete the required fields.', isOpen: true, toastId: modalID});
      }
      if(paramSendMail[ 'to' ].length === 0) {
        setValidateToField(true);
        setModalID(modalID + 1);
        setToastValue({
          type: 'error',
          message: 'Please complete the required fields.',
          isOpen: true,
          toastId: modalID
        });
      }
      if (!paramSendMail[ 'content' ] || !paramSendMail[ 'content' ]?.replace(/<\/?[^>]+(>|$)/g, "")){
        setValidateEmailContent(true);
        setModalID(modalID + 1)
        setToastValue({
          type: 'error',
          message: 'Please complete the required fields.',
          isOpen: true,
          toastId: modalID
        });
      }
    } else {
      sendMail(paramSendMail);
      handleClose();
      setValidateToField(false);
      setValidateFromName(false);
      setValidateEmailContent(false);
      setValidateUserName(false);
      setValidateBaseSender(false);
    }
  }

  const sendMail:any = (paramSendMail:object) => {
    const changeStatusParams = {
      emailId: inboxDetail?.emailId || '',
      status: 'Resolved',
      priority: inboxDetail?.priority || '',
      category: inboxDetail?.category || ''
    }
    paramSendMail['emailId'] = inboxDetail?.emailId || '';
    paramSendMail['zoneId'] = zoneID.toString();
    paramSendMail['settingType'] = "default_email";
    paramSendMail['settingValue'] = Boolean(true);

    setOpenSendMail(false);
    return sendMessageEmail(paramSendMail).then((result:any) => {
      setModalID(modalID + 1)
      setToastValue({type: 'success', message: 'Email sent successfully.', isOpen: true, toastId: modalID})
      handleGetUsedEmail({settingType: "default_email"})
      if(paramSendMail['statusToResolved']){
        return updateTriageInboxes(changeStatusParams).then((result:any) => {
          loadDataTable()
        });
      }
    });
  };

  const handlePurgeMail = (params?:any) => {
    const emailItems:any = []
    let deleteParams:any = {
      emailItems: []
    }
    let paramsData:any
    let emailParams:any
    const rowEmailId = anchorEl?.getAttribute('data-emailid') || dropdownValue;

    emailParams = inboxDetail
    emailParams['recipient'] = inboxDetail['recipient']?.split('<')[1] ? inboxDetail['recipient']?.split('<')[1]?.split('>')[0] : inboxDetail['recipient']

    if(rowSelected.length !== 0 && ((typeof params) === 'object' && params.length === 0 && rowEmailId === '')){
      // multi checkbox
      rowSelected.map((id:any) => emailItems.push({
        "email_id": id.toString(),
        "zone_id": zoneID.toString()
      }));
      deleteParams['emailItems'] = emailItems
    } else if(rowEmailId.length !== 0 && (typeof params) === 'object'){
      // action column
      deleteParams['emailItems'].push({
        "email_id": rowEmailId,
        "zone_id": zoneID.toString()
      })
    } else {
      // action in message details
      deleteParams['emailItems'] = params
      const emailContent = params[0].email_content
      emailParams = {
        emailId: params[0].email_id,
        subject: emailContent?.subject,
        reportedBy: emailContent?.reportedBy,
        sender: emailContent?.email,
        recipient: emailContent?.to,
        priority: emailContent?.priority,
        status: emailContent?.status,
        category: emailContent?.category,
        scanResult: emailContent?.scanResult,
      }
    }

    setAnchorEl(null)
    const accessTokenDecoded:any = jwt_decode(accessToken)
    console.log(accessTokenDecoded)
    paramsData = {
      emailId: deleteParams['emailItems'][0].email_id,
      zoneId: zoneID,
      service_type: "purge",
      emailAction: userData.email,
      nameAction: userData.fullName,
      accessToken: accessToken,
      emailContent: emailParams,
      upn: accessTokenDecoded?.upn,
      tid: accessTokenDecoded?.tid
    }

    const changeStatusPriority = {
      emailId: deleteParams['emailItems'][0].email_id,
      status: 'Resolved',
      priority: paramsData.emailContent.priority,
      category: paramsData.emailContent.category
    }


    return purgeEmail(paramsData).then((result:any) => {
      let status = ""
      console.log(result)
      if(result.status === 200){
        status = "success"
      }else{
        status = "delete"
      }
      openNoticePopup(status, deleteParams['emailItems'])
      return updateTriageInboxes(changeStatusPriority).then((result:any) => {
        loadDataTable()
        setTriggerLoadLog(triggerLoadLog + 1)
      });
    });
    // fake data
    // var random = Math.round(Math.random());
    // var status = ''
    // random < 0.5 ? status = 'success' : status = 'delete'
    // openNoticePopup("success", deleteParams['emailItems'])
  }

  const openNoticePopup = (status:any, emailList:any) => {
    let title = ""
    let content_html = ""
    if(status === "success"){
      title = `Phish Clear has started!`
      content_html = `<div>We have started to find and remove emails that have the <strong>same subject</strong> and the <strong>same sender</strong> across your organisation mailboxes. To view the status go to Message Details > Activity logs or check the action details in the Microsoft Purview portal.</div>
                      <div class="notes">
                          <div>${MESSAGES_STATUS_CHANGED}: <span>Resolved</span></div>
                      </div>`
    } else {
      title = `Failed to remove ${Pluralize('email', emailList.length)}`
      content_html = `<div>There was an issue attempting to remove the selected ${Pluralize('email', emailList.length)}.</div>
                      <div>Please try again later.</div>`
    }

    const content:any = {
      title: title,
      children: <div dangerouslySetInnerHTML={{ __html: content_html }} />,
      action: '',
      textAgree: 'OK',
      variant: status
    }
    setOpenDialog(true);
    setContentModal(content);
  }

  const openNoticeLogin = (params:any) => {
    let title = ""
    let content_html = ""
    title = LOGGED_AZURE_TITLE
    content_html = `<div>You’ve logged into <strong>${params?.account?.username}</strong>’s account. You can now use the remove emails in user’s inbox action.</div>
                    <div>
                        We store Microsoft Purview authorisation for 90 days. These details will be used by PH2 Phish Focus portal for purging emails from your organisation mailboxes, you can cancel this anytime by terminating the refresh token under <strong>${params?.account?.username}</strong>’s account in Azure AD. You will be required to log in again to re-authorise after the 90 day period. 
                    </div>`

    const content:any = {
      title: title,
      children: <div dangerouslySetInnerHTML={{ __html: content_html }} />,
      action: '',
      textAgree: 'OK',
      variant: 'success'
    }
    setOpenDialog(true);
    setContentModal(content);
  }

  // Login Azure Actions
  const LoginAzure = () => {
    const params = {
      "zone_id": "2228",
      "service": "phish_clear",
      "identity_action": "",
      "user_role": user_role,
      "user_id": user_id,
      "app_id": process.env.REACT_APP_PHISH_FOCUS_AZURE_APP_ID,
      "redirect_uri": window.location.origin
    }

    setLoadingPage(true)
    if (!isAuthenticated) {
      params["identity_action"] = "login"
      // call generate authorization_url
      authorizeToken(params).then((response: any) => {
        window.open(
            response.data.authorization_url,
            '_self', 'noopener,noreferrer'
        )
      })
      .catch((e:any )=> {
        console.log(e);
      })
    }
  }

  const totalRowsSelected =  rowSelected.length
  const disabledActions = rowSelected.length === 0;
  const aoColumns = <ActionsColumn isArchive={isArchived} menuData={inboxData} rowSelected={[inboxDetail?.emailId]} open={open} showActionsDropdown={showActionsDropdown} actionRules={actionRules} hideActionsDropdown={hideActionsDropdown} anchorEl={anchorEl} handleDelete={handleActionDelete} handleArchive={handleArchiveMail} handleSendMail={handleSendMail} handlePurgeMail={handlePurgeMail} actionOtherData={actionOtherData} isAuthenticatedAzure={isAuthenticated} loginAzure={LoginAzure} hasAccessToken={accessToken}/>
  const isInboxPage = inboxEvent['id'] && window.location.pathname !== '/inbox'
  const isArchivedPage = inboxEvent['id'] && isArchived
  // console.log(isInboxPage, isArchived, inboxEvent, window.location.pathname)
  return <div className='inbox-page page-content-wrapper'>
    <PageHeader
      setInboxEvent={setInboxEvent} pageTitle={isInboxPage ? 'Message Details' : isArchived ? 'Archived' : 'Inbox'}
      breadCrumbData={isInboxPage ? (isArchivedPage ? inboxDetailArchivedLinks : inboxDetailLinks) : navigationLinks}
      children={isInboxPage ? '' : <DateRangePicker
        start={dateRange.startDate}
        end={dateRange.endDate}
        fontSize={'14px'}
        color={'#011111'}
        colorText={'#011111'}
        width={'165px'}
        height={'36p'}
        cssClass={'daterange-input'}
        dates={dateRange}
        handleApplyDateRange={handleApplyDateRange}
      />}/>
    {
      isInboxPage || isArchivedPage ? <InboxDetails
        emailId={inboxEvent['id']}
        selectedRowEmail={inboxEvent['selectedEmail']}
        handleDelete={(params:any) => handleActionDelete(params, 'InboxDetails')}
        handleArchiveMail={handleArchiveMail}
        tabValue={tabValue}
        rowSelected={rowSelected}
        actionOtherData={actionOtherData}
        handleSendMail={handleSendMail}
        handleActionDelete={handleActionDelete}
        handleEmailChanged={handleEmailChange}
        handlePurgeMail={handlePurgeMail}
        loginAzure={LoginAzure}
        hasAccessToken={accessToken}
        triggerLoadLog={triggerLoadLog}
      /> : <div >

        <div className='page-content-body'>
          {/*<SummaryStatistics setActiveFilter={setActiveFilter} active={filterStatus.type} arrList={inboxStatistic || []} onFilter={handleFilter}></SummaryStatistics>*/}
          <div className='inbox-content'>
            {isMobileOnly ? <div className='search-inbox'>
              <SearchMenuBar
                selectedItems={selectedItems}
                resetFilter={resetFilters}
                handleEnterSearch={onSearchInboxByKeywords}
                onFilter={handleFilter}
                searchInputValue={filterSearchKeyword}
                setSearchInputValue={setFilterSearchKeyword}
                onClearFilter={handleClearFilter}
              />
            </div> : null}
            {isArchived ? null : <TabGroup setValue={setValue} tabValue={tabValue} tabTitles={tabTitles} tabContents={[]} setActiveFilter={setActiveFilter} statistic={statusTab}/>}
            {!isMobileOnly ? <div className='search-inbox'>
              <SearchMenuBar
                selectedItems={selectedItems}
                resetFilter={resetFilters}
                handleEnterSearch={onSearchInboxByKeywords}
                onFilter={handleFilter}
                searchInputValue={filterSearchKeyword}
                setSearchInputValue={setFilterSearchKeyword}
                onClearFilter={handleClearFilter}
              />
            </div> : null}
            <div className='inbox-datatable'>
              {columnData && <DataTable columns={isMobileOnly ? mobileColumns : columns}
                         checkNoFilterColumn ={columnData}
                         data={inboxData}
                         filterData={columnData}
                         rowCount={totalRows}
                         onSelectionModelChange={setRowSelected}
                         CustomPagination={paginationComponent}
                         onChangeRowsPerPage={onSelectedRowsChange}
                         onRowDoubleClick={handleDoubleClickItem}
                         toolbar={customToolbar}
                         actionsColumn={!isMobileOnly && aoColumns}
                         selectionModel={rowSelected}
                         onSortModelChange={onSort}
                         loadingTable={isShowLoading}
                         checkboxSelection={true}
                         pagination={true}
                         initialSortCol={'priority'}
              />}
            </div>
          </div>
        </div>


      </div>
    }
    <Modal
           idModal='modal-phish-clear'
           openDialog={openDialog}
           variant={contentModal.variant}
           title={contentModal.title}
           content={contentModal.content}
           textAgree={contentModal.textAgree}
           actionAgree={() => {
             handleClose()
           }}
           onClose={handleClose}
           children={contentModal.children}
    />
    <Modal idModal='modal-send-mail'
           variant='send-mail'
           disableEnforceFocus={true}
           openDialog={openSendMail}
           title={'Send email'}
           actionAgree={validatePopupSendMail}
           actionCancel={closeSendMail}
           textAgree={'Send'}
           textCancel={'Cancel'}
           onClose={closeSendMail}
           handleOnChange={(e:any) => setParamSendMail(() => {
             return  ({
               ...paramSendMail,
               'statusToResolved': e.target.checked,
             })
           })}
    >
      {inboxDetail && <SendMailModal
          defaultParams={defaultParams}
          paramSendMail={paramSendMail}
          setParamSendMail={setParamSendMail}
          scanResult={{'scan-result': inboxDetail?.scanResult}}
          validateFromName={validateFromName}
          setValidateFromName={setValidateFromName}
          fromNameError={'This field is required.'}
          validateToField={validateToField}
          setValidateToField={setValidateToField}
          validateEmailContent={validateEmailContent}
          setValidateEmailContent={setValidateEmailContent}
          editorError={'This field is required.'}
          validateUserName={validateUserName}
          setValidateUserName={setValidateUserName}
          validateBaseSender={validateBaseSender}
          setValidateBaseSender={setValidateBaseSender}
          isMessageDownload={true}
      />}
    </Modal>
    <LoadingPage open={loadingPage}/>
    <Toast isOpen={toastValue.isOpen} type={toastValue.type} message={toastValue.message} toastId={toastValue.toastId} autoHideDuration={2000}/>
  </div>
};

export const Inbox = ChangeWithZone(InboxFull);
